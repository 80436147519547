import { NextPage } from 'next'

const HomePage: NextPage = () => {
  return (
    <div>
      <h1>Hello, world!</h1>
    </div>
  )
}

export default HomePage
